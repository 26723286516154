import {
  deliveryFulfillmentType,
  FulfillmentOption,
  LeaflyFulfillment,
  LeaflyFulfillmentDetails,
  pickupFulfillmentType,
  ShippingFulfillment,
  ShippingFulfillmentDetails,
  shippingFulfillmentType,
  Variant,
} from "custom-types/Fulfillment";

export const toTitleCase = (str = "") =>
  str.replace(/./, (m) => m.toUpperCase());

export const getPaymentMethodsString = (
  acceptedPayments: Record<string, boolean>,
  paymentMethods: string[] = [],
) => {
  // hardcoding options to ensure consistent display order
  const defaultPickupPayment = ["cash", "credit", "debit"].filter(
    (method) => acceptedPayments[method],
  );

  if (!paymentMethods || !paymentMethods.length) {
    paymentMethods = defaultPickupPayment.length
      ? defaultPickupPayment
      : ["cash"];
  }
  const appendCardStr = (m = "") =>
    toTitleCase(/credit/i.test(m) ? m + " Card" : m);
  const str = paymentMethods.map(appendCardStr).join(", ");
  return str;
};

export const formatDeliveryFee = (fee?: number | null) => {
  if (fee === undefined || fee === null) {
    return "";
  }
  return Number(fee) === 0 ? "Free" : `$${fee} fee`;
};

export const formatDeliveryMin = (min?: number | null) => {
  if (min === undefined || min === null) {
    return "";
  }
  return Number(min) === 0 ? "No minimum" : `$${min} min.`;
};

export const fulfillmentCookieName = "userFulfillmentPreference";

export const getInitialFulfillmentOption = (
  options: FulfillmentOption[] | null,
) => {
  return (
    (options &&
      options.find((opt: FulfillmentOption) => !!opt.isInitialSelection)) ??
    null
  );
};

export const filterVariantsByMedStatus = (
  isMedical: boolean,
  variants: Variant[],
) => {
  return variants.length ? variants.filter((v) => v.medical === isMedical) : [];
};

export const getInitialVariant = (variants: Variant[]) =>
  variants.length
    ? variants.find((v) => !!v.isInitialSelection) || variants[0]
    : null;

export const getFulfillmentOptionsByType = (options: FulfillmentOption[]) => {
  const findOptionByType = (type: string) =>
    options?.find((opt: FulfillmentOption) => opt.type === type) || null;

  return {
    deliveryFulfillment: findOptionByType(
      deliveryFulfillmentType,
    ) as LeaflyFulfillment | null,
    pickupFulfillment: findOptionByType(
      pickupFulfillmentType,
    ) as LeaflyFulfillment | null,
    shippingFulfillment: findOptionByType(
      shippingFulfillmentType,
    ) as ShippingFulfillment | null,
  };
};

export const getInitialFulfillmentValues = (options: FulfillmentOption[]) => {
  const initialOption = getInitialFulfillmentOption(options);
  const hasVariants = isLeaflyFulfillment(initialOption);

  const initialVariant = hasVariants
    ? getInitialVariant(initialOption.details.variants)
    : null;

  const initialVariants =
    hasVariants && initialVariant
      ? filterVariantsByMedStatus(
          initialVariant.medical,
          initialOption.details.variants,
        )
      : [];

  return {
    initialOption,
    initialVariant,
    initialVariants,
  };
};

export const hasMedAndRecVariants = (option: FulfillmentOption | null) => {
  const hasVariants = isLeaflyFulfillment(option);

  return (
    hasVariants &&
    option.details.variants.some((v) => !!v.medical) &&
    option.details.variants.some((v) => !v.medical)
  );
};

// type guard for pickup and delivery fulfillment options
export const isLeaflyFulfillment = (
  option: FulfillmentOption | null,
): option is FulfillmentOption & {
  details: LeaflyFulfillmentDetails;
} => {
  return (
    !!option &&
    option.type !== shippingFulfillmentType &&
    option.details !== null &&
    "variants" in option.details
  );
};

// type guard for shipping fulfillment option
export const isShippingFulfillment = (
  option: FulfillmentOption | null,
): option is FulfillmentOption & {
  details: ShippingFulfillmentDetails;
} => {
  return (
    !!option &&
    option.type === shippingFulfillmentType &&
    option.details !== null &&
    "affiliateUrl" in option.details
  );
};
